<template>
  <div class="dimbox register user scroll">
    <div class="mHeader">
      <p>알림 설정</p>
    </div>
    <div class="mBody">
      <div>
        <div class="flexB">
          <p>웹 페이지 알림 설정</p>
          <el-switch
            v-model="alarm[0]"
            active-color="#40A6A3"
            inactive-color="#DCDFE6"
          >
          </el-switch>
        </div>
        <div class="flexB">
          <p>이메일 알림 설정</p>
          <el-switch
            v-model="alarm[1]"
            active-color="#40A6A3"
            inactive-color="#DCDFE6"
          >
          </el-switch>
        </div>
        <div class="flexB">
          <p>문자 알림 설정</p>
          <el-switch
            v-model="alarm[2]"
            active-color="#40A6A3"
            inactive-color="#DCDFE6"
          >
          </el-switch>
        </div>
        <div class="flexB space multiple">
          <p>이메일</p>
          <div>
            <div v-for="(data, i) in email" :key="i + 'i'">
              <input type="text" v-model="emailList[i]" />
            </div>
          </div>
        </div>
        <div class="flexB space multiple">
          <p>휴대전화번호</p>
          <div>
            <div v-for="(data, k) in sms" :key="k + 'k'">
              <input type="text" v-model="smsList[k]" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mFooter">
      <div class="buttonWrap">
        <button @click="close" class="basic close">취소</button>
        <button class="point" @click="submit">등록</button>
      </div>
    </div>
  </div>
</template>
<script>
import { fetchEquipmentAlarm, updateEquipment } from "@/api/index";
export default {
  data() {
    return {
      alarm: [false, false, false],
      equipId: localStorage.getItem("equipId"),
      smsList: [],
      emailList: [],
      emailtemp: [],
      sms: 0,
      email: 0,
      equipmentId: "",
    };
  },
  created() {
    this.getEquipmentInfo();
  },
  mounted() {},
  methods: {
    close() {
      this.$emit("close");
    },
    /**
     * 설비 알람정보 가져오기 기능
     */
    getEquipmentInfo() {
      fetchEquipmentAlarm(this.equipId).then((res) => {
        if (res.data.status == 200) {
          this.smsList = res.data.data.smsList;
          this.sms = res.data.data.sms;
          this.emailList = res.data.data.emailList;
          this.email = res.data.data.email;
          this.alarm = res.data.data.alarm;
          this.equipmentId = res.data.data._id;
        } else {
          console.log(res.data.message);
        }
      });
    },
        /**
     * 설비 알람정보 수정 기능
     */
    submit() {
      let data = {
        _id: this.equipmentId,
        smsList: this.smsList,
        emailList: this.emailList,
        alarm: this.alarm,
      };
      updateEquipment(data).then((res) => {
        if (res.data.status != 200) {
          console.log(res.data.message);
        } else {
          this.$store.dispatch("getEquipmentInfo");
          this.close();
        }
      });
    },
  },
};
</script>
