<template>
  <apexchart
    type="line"
    width="100%"
    height="100%"
    :options="chartOptions"
    :series="series"
    ref="chart"
  ></apexchart>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    infoData: {
      type: Array,
    },
    xData: {
      type: Array,
    },
  },
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          type: "area",
          stacked: false,
          zoom: {
            type: "x",
            enabled: true,
            autoScaleYaxis: true,
          },
          toolbar: {
            show: false,
            autoSelected: "zoom",
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: true,
          showForSingleSeries: true,
          showForNullSeries: true,
          showForZeroSeries: true,
          position: "bottom",
          horizontalAlign: "center",
          floating: false,
          fontSize: "14px",
          fontFamily: "Helvetica, Arial",
          fontWeight: 400,
          formatter: undefined,
          inverseOrder: false,
          width: undefined,
          height: undefined,
          tooltipHoverFormatter: undefined,
          customLegendItems: [],
          offsetX: 0,
          offsetY: 0,
          labels: {
            colors: undefined,
            useSeriesColors: false,
          },
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: "#fff",
            fillColors: undefined,
            radius: 12,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0,
          },
          itemMargin: {
            horizontal: 5,
            vertical: 0,
          },
          onItemClick: {
            toggleDataSeries: true,
          },
          onItemHover: {
            highlightDataSeries: true,
          },
        },
        colors: ["#499DF5", "#5845EA"],
        stroke: {
          width: 2,
          curve: "smooth",
        },
        markers: {
          size: 0,
        },
        title: {
          align: "left",
        },
        yaxis: {
          show: false,
          opposite: true,
          tickAmount: 2,
          title: {
            show: false,
          },
        },
        xaxis: {
          categories: [],
          type: "category",
          labels: {
            style: {
              fontSize: "12px",
            },
            rotate: 0,
            hideOverlappingLabels: true,
          },
        },
        /**
         * 데이터에 맞게 표시되는 Y 툴팁을 맞춤
         */
        tooltip: {
          shared: false,
          y: {
            formatter: function (val) {
              let temp = 0;
              if (val == 0) {
                temp = "정상";
              } else if (val == 1) {
                temp = "경고";
              } else {
                temp = "비정상";
              }
              return temp;
            },
          },
        },
        responsive: [
          {
            breakpoint: 2300,
            options: {
              chart: {},
              legend: {
                horizontalAlign: "left",
              },
            },
          },
          {
            breakpoint: 480,
            options: {
              chart: {},
              yaxis: {
                labels: {
                  show: false,
                  style: {
                    fontSize: "10px",
                  },
                },
              },
            },
          },
        ],
      },
    };
  },
  watch: {
    infoData: function () {
      this.updateData();
    },
    xData: function () {
      this.updateData();
    },
  },
  methods: {
    updateData() {
      if (this.infoData.length > 0 || this.xData.length > 0) {
        this.$refs.chart.updateOptions({
          series: [
            {
              name: "이상감지 예측 값",
              data: this.infoData,
            },
          ],
          xaxis: {
            type: "category",
            categories: this.xData,
            labels: {
              show: false,
            },
          },
          yaxis: {
            show: true,
            opposite: true,
            tickAmount: 2,
            /**
             * 데이터에 맞게 표시되는 Y 라벨을 맞춤
             */
            labels: {
              formatter: function (val) {
                let temp = 0;
                if (val == 0) {
                  temp = "정상";
                } else if (val == 1) {
                  temp = "경고";
                } else {
                  temp = "비정상";
                }
                return temp;
              },
            },
            title: {
              show: false,
            },
          },
        });
      }
    },
  },
};
</script>
