<template>
  <div
    class="container"
    v-loading="loadings"
    element-loading-text="데이터를 업로드 하는 중입니다."
  >
    <section class="contWrap">
      <article class="flexB select">
        <el-select v-model="value" placeholder="ID" @change="handleEquipment">
          <el-option
            v-for="(item, i) in equipment"
            :key="i"
            :label="item.equipmentId"
            :value="item.equipmentId"
          >
          </el-option>
        </el-select>
        <div class="flex buttons">
          <button class="basic" @click="handleDisplayModal()">
            <span class="material-icons-outlined"> monitor </span>
            <em>디스플레이 설정</em>
          </button>
          <button class="basic" @click="handleAlarmModal()">
            <span class="material-icons-outlined"> settings </span>
            <em>알림 설정</em>
          </button>
          <router-link to="/policy" class="basic"
            ><span class="material-icons-outlined"> gavel </span>
            <em>정책 관리</em>
          </router-link>
        </div>
      </article>
      <article class="monitoring">
        <div class="left card" v-if="display[0] == true">
          <div class="tit">
            <h3>이상감지 예측 결과</h3>
            <router-link to="/equipment"
              ><span class="material-icons"> add </span
              ><em>자세히 보기</em></router-link
            >
          </div>
          <div class="chartBox scrollBox">
            <div>
              <LineChart :infoData="sensorChartData" :xData="sensorChartDate" />
            </div>
          </div>

          <div class="dataBox">
            <div class="status">
              <span
                class="material-icons normal"
                :class="{ active: anomaly == 0 }"
              >
                check_circle
              </span>
              <span
                class="material-icons warning"
                :class="{ active: anomaly == 1 }"
              >
                error </span
              ><span
                class="material-icons abnormal"
                :class="{ active: anomaly == 2 }"
              >
                cancel
              </span>
            </div>
            <div class="flexB">
              <p><span class="date"></span>Date</p>
              <span>{{ sensorDate }}</span>
            </div>
            <div class="flexB">
              <p><span class="time"></span>Time</p>
              <span>{{ sensorTime }}</span>
            </div>
            <div class="flexB">
              <p><span class="condition"></span>현재 상태</p>
              <span>
                {{
                  anomaly == 0
                    ? "정상"
                    : anomaly == 1
                    ? "경고"
                    : anomaly == 2
                    ? "비정상"
                    : ""
                }}
              </span>
            </div>
            <div class="flexB">
              <p><span class="battery"></span>잔여수명</p>
              <span>{{ rul }}%</span>
            </div>
            <div class="flexB">
              <p><span class="prediction"></span>전조 예측</p>
              <span>{{ forecast }}분 후 정비 바람</span>
            </div>
          </div>
        </div>
        <div class="right card" v-if="display[1] == true">
          <div class="tit">
            <h3>네트워크 트래픽 진단 결과</h3>
            <router-link to="/network"
              ><span class="material-icons"> add </span
              ><em>자세히 보기</em></router-link
            >
          </div>
          <div class="chartBox">
            <LineChart2
              :infoData="networkChartData"
              :xData="networkChartDate"
            />
          </div>
          <div class="dataBox">
            <div class="status">
              <span
                class="material-icons normal"
                :class="{ active: networkPrediction == 'Normal' }"
              >
                check_circle
              </span>
              <span
                class="material-icons abnormal"
                :class="{ active: networkPrediction != 'Normal' }"
              >
                cancel
              </span>
            </div>
            <div class="flexB">
              <p><span class="date"></span>Date</p>
              <span>{{ networkDate }}</span>
            </div>
            <div class="flexB">
              <p><span class="time"></span>Time</p>
              <span>{{ networkTime }}</span>
            </div>
            <div class="flexB">
              <p><span class="condition"></span>현재 상태</p>
              <span>{{
                networkPrediction == "Normal" ? "정상" : "비정상"
              }}</span>
            </div>
            <ul class="flex">
              <li :class="{ abnormal: networkPrediction == 'Normal' }">
                Normal
              </li>
              <li :class="{ abnormal: networkPrediction == 'Scan Port OS' }">
                Scan Port OS
              </li>
              <li :class="{ abnormal: networkPrediction == 'Scan Hostport' }">
                Scan Hostport
              </li>
              <li :class="{ abnormal: networkPrediction == 'DoS-Synflooding' }">
                DoS-Synflooding
              </li>
              <li
                :class="{ abnormal: networkPrediction == 'Mirai-Ackflooding' }"
              >
                Mirai-Ackflooding
              </li>
              <li
                :class="{ abnormal: networkPrediction == 'MITM ARP Spoofing' }"
              >
                MITM ARP Spoofing
              </li>
              <li
                :class="{ abnormal: networkPrediction == 'Mirai-UDP Floodin' }"
              >
                Mirai-UDP Flooding
              </li>
              <li
                :class="{
                  abnormal: networkPrediction == 'Mirai-HTTP Flooding',
                }"
              >
                Mirai-HTTP Flooding
              </li>
              <li
                :class="{
                  abnormal: networkPrediction == 'Mirai-Hostbruteforceg',
                }"
              >
                Mirai-Hostbruteforceg
              </li>
            </ul>
          </div>
        </div>
      </article>
      <article class="sensor">
        <div class="left card" v-if="display[2] == true">
          <div class="tit">
            <h3>스마트 센서</h3>
            <router-link to="/smartsensor"
              ><span class="material-icons"> add </span
              ><em>자세히 보기</em></router-link
            >
          </div>
          <div class="charts">
            <div
              class="chartBox"
              v-for="(item, y) in smartSensor1.slice(0, 5)"
              :key="y + 'y'"
            >
              <p class="date">
                <span>{{ item.startDate }}</span>
                <span>~ {{ item.endDate }}</span>
              </p>
              <LineChart11 :infoData="item.magnitude" :xData="item.Hz" />
            </div>
          </div>
        </div>
        <div class="right card" v-if="display[3] == true">
          <div class="tit">
            <h3>실시간 센서 트렌드</h3>
            <router-link to="/localsensor"
              ><span class="material-icons"> add </span
              ><em>자세히 보기</em></router-link
            >
          </div>
          <div class="charts">
            <div
              class="chartBox"
              v-for="(item, t) in localSensor1.slice(0, 5)"
              :key="t + 't'"
            >
              <p class="date">
                <span>{{ item.startDate }}</span>
                <span>~ {{ item.endDate }}</span>
              </p>
              <LineChart10 :infoData="item.magnitude" :xData="item.Hz" />
            </div>
          </div>
        </div>
      </article>
      <article class="network">
        <div class="card">
          <div class="flexB title">
            <h3>
              네트워크 트래픽 현황
              <button>
                <span class="material-icons-outlined"> file_download </span>
              </button>
            </h3>
            <div class="flex">
              <div class="block">
                <el-date-picker
                  @change="search"
                  v-model="startTime"
                  type="datetime"
                  placeholder="시작 시간"
                >
                </el-date-picker>
                <el-date-picker
                  @change="search"
                  v-model="endTime"
                  type="datetime"
                  placeholder="마감 시간"
                >
                </el-date-picker>
              </div>
              <button class="basic" @click="excelDownload">
                <span class="material-icons-outlined"> file_download </span>
              </button>
            </div>
          </div>
          <div class="tableBox">
            <table>
              <colgroup>
                <col style="width: 20%" />
                <col style="width: 15%" />
                <col style="width: 20%" />
                <col style="width: 10%" />
                <col style="width: 10%" />
                <col style="width: 25%" />
              </colgroup>
              <tr>
                <th>Src_IP</th>
                <th>Src_Port</th>
                <th>Dst_IP</th>
                <th>Dst_Port</th>
                <th>Protocol</th>
                <th>Timestamp</th>
              </tr>
              <tr v-for="(data, k) in netStatusList" :key="k">
                <td>{{ data.Src_IP }}</td>
                <td>{{ data.Src_Port }}</td>
                <td>{{ data.Dst_IP }}</td>
                <td>{{ data.Dst_Port }}</td>
                <td>{{ data.Protocol }}</td>
                <td>{{ data.Timestamp }}</td>
              </tr>
            </table>
          </div>
          <div class="noData" v-if="netStatusList.length < 1">
            <p>데이터가 없습니다.</p>
          </div>
        </div>
      </article>
    </section>
    <transition name="fade">
      <div class="dim" v-if="modalVisible">
        <DisplayModal
          v-if="displayVisible"
          @close="close"
          :display="display"
          @checkItems="checkItems"
        />
        <AlarmModal v-if="alarmVisible" @close="close" />
        <div class="dimbg" @click="close"></div>
      </div>
    </transition>
  </div>
</template>
<script>
import { fetchEquipmentData, fetchNetworkStatus } from "@/api/index";
import { mapState } from "vuex";
import LineChart from "@/components/charts/LineChart";
import LineChart2 from "@/components/charts/LineChart2";
import LineChart10 from "@/components/charts/LineChart10";
import LineChart11 from "@/components/charts/LineChart11";
import DisplayModal from "@/components/pop/DisplayModal";
import AlarmModal from "@/components/pop/AlarmModal";
import moment from "moment";
import XLSX from "xlsx";

export default {
  components: {
    LineChart,
    LineChart2,
    LineChart10,
    LineChart11,
    DisplayModal,
    AlarmModal,
  },

  data() {
    return {
      moment: moment,
      modalVisible: false,
      displayVisible: false,
      alarmVisible: false,
      loadings: false,
      value: localStorage.getItem("equipId"),
      checkItems: [],
      display: [true, true, false, true],
      networkChartDate: [],
      networkChartData: [],
      sensorChartData: [],
      sensorChartDate: [],
      sensorDate: "",
      sensorTime: "",
      anomaly: "",
      rul: 0,
      forecast: 0,
      networkDate: "",
      networkTime: "",
      networkPrediction: "",
      startTime: "",
      endTime: "",
      netStatusList: [],
      netStatusTotal: 0,
      netStatusPage: 1,
      sensor1: [],
      network1: [],
      smartSensor1: [],
      localSensor1: [],
    };
  },
  computed: {
    ...mapState([
      "userId",
      "_id",
      "equipment",
      "network",
      "sensor",
      "smartSensor",
      "localSensor",
    ]),
  },
  created() {
    this.$store.dispatch("SET_NAV_INDEX", "1");
  },
  mounted() {
    this.fetchData();
    let box = document.getElementsByClassName("chartBox");
    for (let i = 0; i < box.length; i++) {
      box[i].scrollLeft = box[i].scrollWidth;
    }
  },
  watch: {
    smartSensor: function (newVal) {
      this.Sensorfunc(newVal, 1);
    },
    localSensor: function (newVal) {
      this.Sensorfunc(newVal, 2);
    },
    network: function (newVal) {
      this.network1 = newVal;
    },
    network1: function (newVal) {
      (this.networkChartDate = []), (this.networkChartData = []);
      if (newVal.length > 0) {
        newVal != "" &&
          newVal.map((item) => {
            // let temDate = `${item.Date}`;
            // console.log("item.Date", item.Timestamp, "item.prediction", item.prediction);
            let stringDate = item.Timestamp.split("T");
            this.networkChartDate.push(
              `${stringDate[0].split("-")[1]}-${stringDate[0].split("-")[2]} ${stringDate[1].split(".")[0]}`
              // moment(item.Timestamp).format("MM-DD HH:mm:ss")
              // `${temDate.substring(temDate.length - 2)} 분`
            );
            this.networkChartData.push(item.prediction == "Normal" ? 0 : 1);
          });
        if (Array.isArray(newVal)) {
          let data = newVal[newVal.length - 1];
          let stringDate = data.Timestamp.split("T");
          // let date = `${data.Date}`;
          // let year = date.substr(0, 4);
          // let month = date.substr(4, 2);
          // let day = date.substr(6, 2);
          // let hour = date.substr(8, 2);
          // let min = date.substr(10, 2);
          this.networkDate = stringDate[0];
          this.networkTime = stringDate[1].split(".")[0];
          this.networkPrediction = data.prediction;
        } else {
          this.networkDate = "";
          this.networkTime = "";
          this.networkPrediction = "";
        }
      }
    },
    sensor: function (newVal) {
      this.sensor1 = newVal;
    },
    sensor1: function (newVal) {
      (this.sensorChartDate = []), (this.sensorChartData = []);
      if (newVal.length > 0) {
        newVal != "" &&
          newVal.map((item) => {
            this.sensorChartDate.push(moment(item.Time).subtract(9, "hours").format("MM-DD HH:mm:ss"));
            // this.sensorChartDate.push(item.Time);
            this.sensorChartData.push(item.anomaly);
          });
        if (Array.isArray(newVal)) {
          let data = newVal[newVal.length - 1];
          this.sensorDate = moment(data.Time).subtract(9, "hours").format("YYYY.MM.DD");
          this.sensorTime = moment(data.Time).subtract(9, "hours").format("HH:mm:ss");
          this.anomaly = data.anomaly;
          this.rul = data.RUL;
          this.forecast = data.forecast;
        } else {
          this.sensorDate = "";
          this.sensorTime = "";
          this.anomaly = 2;
          this.rul = 0;
          this.forecast = 0;
        }
      }
    },
  },
  methods: {
    /**
     * 네트워크 트래픽 현황 정보 가져오기 기능
     */
    async search() {
      this.netStatusList = [];
      if (this.startTime == "" || this.startTime == undefined) {
        return;
      } else if (this.endTime == "" || this.startTime == undefined) {
        return;
      } else if (this.startTime >= this.endTime) {
        return;
      } else if (this.startTime && this.endTime) {
        let equipObj = this.equipment.find(
          (item) => item.equipmentId == this.value
        );
        let params = {
          networkStatusApiIp: equipObj.networkStatusApiIp,
          from_date: `${moment(this.startTime).format(
            "YYYY-MM-DDTHH:mm:ss"
          )}.000Z`,
          to_date: `${moment(this.endTime).format("YYYY-MM-DDTHH:mm:ss")}.000Z`,
        };
        await fetchNetworkStatus(params)
          .then((res) => {
            if (res.data.data != undefined) {
              this.netStatusList = res.data.data;
            }
          })
          .catch((err) => {
            console.log(err.message);
            alert(
              "네트워크 트랙픽 현황 데이터를 확인할 수 없습니다. 다시 한 번 확인해주세요."
            );
            return;
          });
      }
    },
    /**
     * 네트워크 트래픽 현황 데이터 엑셀 다운로드 기능
     */
    async excelDownload() {
      if (this.startTime == "") {
        alert("시작일을 입력해주세요.");
        return;
      } else if (this.endTime == "") {
        alert("종료일을 입력해주세요.");
        return;
      } else if (this.startTime >= this.endTime) {
        alert("시작일과 종료일을 다시 한 번 확인해주세요.");
        return;
      }
      let equipObj = this.equipment.find(
        (item) => item.equipmentId == this.value
      );
      let params = {
        networkStatusApiIp: equipObj.networkStatusApiIp,
        from_date: `${moment(this.startTime).format(
          "YYYY-MM-DDTHH:mm:ss"
        )}.000Z`,
        to_date: `${moment(this.endTime).format("YYYY-MM-DDTHH:mm:ss")}.000Z`,
      };
      await fetchNetworkStatus(params)
        .then((res) => {
          if (res.data.data != undefined) {
            this.netStatusList = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err.message);
          alert(
            "네트워크 트랙픽 현황 데이터를 확인할 수 없습니다. 다시 한 번 확인해주세요."
          );
          return;
        });
      if (this.netStatusList.length < 1) {
        alert("기간 내 조회되는 데이터가 없습니다.");
        return;
      }
      let tempInfo = this.netStatusList.map((item) => {
        let data = {
          Src_IP: item.Src_IP,
          Src_Port: item.Src_Port,
          Dst_IP: item.Dst_IP,
          Dst_Port: item.Dst_Port,
          Protocol: item.Protocol,
          Timestamp: item.Timestamp,
        };
        return data;
      });
      let networkStatusList = XLSX.utils.json_to_sheet(tempInfo);
      let wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(
        wb,
        networkStatusList,
        "네트워크 트래픽 현황"
      );
      XLSX.writeFile(
        wb,
        moment(new Date()).format("YYYY.MM.DD") +"_네트워크 트래픽 현황" +".xlsx"
      );
    },
    /**
     * 실시간 센서 트렌드 정보 변환 후 재배포 기능
     */
    Sensorfunc(newVal, num) {
      let smartSensor1Temp = [];
      newVal.map((item, i) => {
        let temp1 = [];
        let temp2 = [];
        let timeSort = item.data.sort((a, b) => {
          if (b.time < a.time) return 1;
          if (b.time > a.time) return -1;
          return 0;
        });
        timeSort.map((dt) => {
          if(Object.keys(dt).length == 2) {
            let dateFormat = dt.time.split("T");
            let dateFormat2 = dateFormat[0].split("-");
            temp1.push(dt.avg_freq);
            temp2.push(`${dateFormat2[1]}-${dateFormat2[2]} ${dateFormat[1].split(".")[0]}`);
          } else if (Object.keys(dt).length == 3) {
            temp1.push(dt.magnitude);
            temp2.push(String(dt.Hz.toFixed(10)));
          }
        });
        let dateFormat = item.data[0].time.split("T");
        let dateFormat2 = item.data[item.data.length - 1].time.split("T");
        smartSensor1Temp[i] = {
          magnitude: temp1,
          Hz: temp2,
          apiName: item.apiName,
          startDate: `${dateFormat[0]} ${dateFormat[1].split(".")[0]}`,
          endDate: `${dateFormat2[0]} ${dateFormat2[1].split(".")[0]}`,
        };
      });
      if (num == 1) {
        this.smartSensor1 = smartSensor1Temp;
      } else if (num == 2) {
        this.localSensor1 = smartSensor1Temp;
      }
    },
    /**
     * 페이지 생성시 데이터 트리거 기능
     */
    fetchData() {
      this.sensor1 = this.sensor;
      this.network1 = this.network;
      this.Sensorfunc(this.smartSensor, 1);
      this.Sensorfunc(this.localSensor, 2);
    },
    /**
     * 설비 ID  정보 가져오기 기능
     */
    handleEquipment() {
      localStorage.setItem("equipId", this.value);
      let equipObj = this.equipment.find(
        (item) => item.equipmentId == this.value
      );
      this.getApiData(equipObj);
    },
    /**
     * 설비 ID별 API 데이터 가져오기 기능
     */
    getApiData(equipObj) {
      let params = {
        networkApi: equipObj.networkApiIp,
        sensorApi: equipObj.sensorApiIp,
      };
      fetchEquipmentData(params).then((res) => {
        if (res.data.status != 200) {
          // this.$store.dispatch("SET_EQUIPMENT_DATA", ["", ""]);
        } else {
          this.$store.dispatch("SET_EQUIPMENT_DATA", res.data.data);
        }
      });
    },
    openModal() {
      this.modalVisible = true;
    },
    closeModal() {
      this.modalVisible = false;
    },
    handleDisplayModal() {
      this.modalVisible = true;
      this.displayVisible = true;
    },
    handleAlarmModal() {
      this.modalVisible = true;
      this.alarmVisible = true;
    },
    close() {
      this.modalVisible = false;
      this.displayVisible = false;
      this.alarmVisible = false;
    },
  },
};
</script>
