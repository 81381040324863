<template>
  <apexchart
    type="line"
    width="100%"
    height="100%"
    :options="chartOptions"
    :series="series"
    ref="chart"
  ></apexchart>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import moment from "moment";
export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    infoData: {
      type: Array,
    },
    xData: {
      type: Array,
    },
  },
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          type: "area",
          stacked: false,
          zoom: {
            type: "x",
            enabled: true,
            autoScaleYaxis: true,
          },
          toolbar: {
            show: false,
            autoSelected: "zoom",
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: true,
          showForSingleSeries: true,
          showForNullSeries: true,
          showForZeroSeries: true,
          position: "bottom",
          horizontalAlign: "center",
          floating: false,
          fontSize: "14px",
          fontFamily: "Helvetica, Arial",
          fontWeight: 400,
          formatter: undefined,
          inverseOrder: false,
          width: undefined,
          height: undefined,
          tooltipHoverFormatter: undefined,
          customLegendItems: [],
          offsetX: 0,
          offsetY: 0,
          labels: {
            colors: undefined,
            useSeriesColors: false,
          },
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: "#fff",
            fillColors: undefined,
            radius: 12,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0,
          },
          itemMargin: {
            horizontal: 5,
            vertical: 0,
          },
          onItemClick: {
            toggleDataSeries: true,
          },
          onItemHover: {
            highlightDataSeries: true,
          },
        },
        colors: ["#40A6A3", "#40A6A3"],
        stroke: {
          width: 2,
          curve: "straight",
        },
        markers: {
          size: 0,
        },
        title: {
          align: "left",
        },
        yaxis: {
          show: false,
          tickAmount: 1,
          axisBorder: {
            show: true,
            color: "#e0e0e0",
            offsetX: 0,
            offsetY: 0,
          },
          title: {
            show: false,
          },
        },
        xaxis: {
          categories: [],
          type: "category",
          labels: {
            rotate: 0,
            hideOverlappingLabels: true,
            formatter: function (val) {
              return moment(val).format("HH:mm");
            },
          },
        },
        tooltip: {
          shared: false,
          y: {
            formatter: function (val) {
              if (val == 0) {
                return "정상";
              } else {
                return "비정상";
              }
            },
          },
        },
        responsive: [
          {
            breakpoint: 2300,
            options: {
              chart: {},
              legend: {
                horizontalAlign: "left",
              },
            },
          },
          {
            breakpoint: 480,
            options: {
              chart: {},
              yaxis: {
                labels: {
                  show: false,
                  style: {
                    fontSize: "10px",
                  },
                },
              },
            },
          },
        ],
      },
    };
  },
  watch: {
    infoData: function () {
      this.updateData();
    },
    xData: function () {
      this.updateData();
    },
  },
  methods: {
    updateData() {
      if (this.infoData.length > 0 || this.xData.length > 0) {
        this.$refs.chart.updateOptions({
          series: [
            {
              name: "네트워크 진단 결과",
              data: this.infoData,
            },
          ],
          yaxis: {
            opposite: true,
            tickAmount: 1,
            labels: {
              formatter: function (val) {
                let temp = 0;
                if (val == 0) {
                  temp = "정상";
                } else {
                  temp = "비정상";
                }
                return temp;
              },
            },
          },
          xaxis: {
            type: "category",
            categories: this.xData,
            labels: {
              show: false,
            },
          },
        });
      }
    },
  },
};
</script>
