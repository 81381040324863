<template>
  <div class="dimbox register user">
    <div class="mHeader">
      <p>디스플레이 설정</p>
    </div>
    <div class="mBody">
      <div class="flexB">
        <p>이상 감지 예측 결과 차트</p>
        <el-switch
          v-model="display[0]"
          active-color="#40A6A3"
          inactive-color="#DCDFE6"
        >
        </el-switch>
      </div>
      <div class="flexB">
        <p>네트워크 트래픽 진단 결과 차트</p>
        <el-switch
          v-model="display[1]"
          active-color="#40A6A3"
          inactive-color="#DCDFE6"
        >
        </el-switch>
      </div>
      <div class="flexB">
        <p>실시간 센서 트렌드</p>
        <el-switch
          v-model="display[3]"
          active-color="#40A6A3"
          inactive-color="#DCDFE6"
        >
        </el-switch>
      </div>
      <!-- <div class="flexB">
        <p>스마트 센서 차트</p>
        <el-switch
          v-model="display[2]"
          active-color="#40A6A3"
          inactive-color="#DCDFE6"
        >
        </el-switch>
      </div> -->
      <div class="buttonWrap display">
        <button class="point" @click="submit">확인</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["display"],
  data() {
    return {};
  },
  mounted() {},
  methods: {
    close() {
      this.$emit("close");
    },
    submit() {
      this.close();
    },
  },
};
</script>
