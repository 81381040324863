<template>
  <apexchart
    type="line"
    height="100%"
    :options="chartOptions"
    :series="series"
    ref="chart"
  ></apexchart>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    infoData: {
      type: Array,
    },
    xData: {
      type: Array,
    },
  },
  data() {
    return {
      series: [{
              name: "Vibration",
              data: this.infoData,
          }],
          chartOptions: {
                legend: {
      show: true,
      showForSingleSeries: true,
      showForNullSeries: true,
      showForZeroSeries: true,
      position: 'bottom',
      horizontalAlign: 'center', 
      floating: false,
      fontSize: '12px',
      fontFamily: 'Helvetica, Arial',
      fontWeight: 400,
      formatter: undefined,
      inverseOrder: false,
      width: undefined,
      height: undefined,
      tooltipHoverFormatter: undefined,
      customLegendItems: [],
      offsetX: 0,
      offsetY: 0,
      labels: {
          colors: undefined,
          useSeriesColors: false
      },
      markers: {
          width: 8,
          height: 8,
          strokeWidth: 0,
          fillColors: undefined,
          radius: 8,
          customHTML: undefined,
          onClick: undefined,
          offsetX: 0,
          offsetY: 0
      },
      itemMargin: {
          horizontal: 5,
          vertical: 0
      },
      onItemClick: {
          toggleDataSeries: true
      },
      onItemHover: {
          highlightDataSeries: true
      },
  },
            colors: ['#40a6a3'],
  
            chart: {
              type: 'line',
              zoom: {
                enabled: false
              },
              toolbar: {
                show: false,
              },
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              width: "2",
              curve: 'smooth'
            },
            title: {
              show: false,
  
            },
            grid: {
              row: {
              },
            },
        xaxis: {
          type: "category",
          categories: this.xData,
          labels: {
            show: false,
          },
        },
            yaxis: {
              show: false,
            },
          },

    };
  },
  watch: {
    infoData: function () {
      this.updateData();
    },
    xData: function () {
      this.updateData();
    },
  },
  methods: {
    updateData() {
      this.$refs.chart.updateOptions({
        series: [
          {
            name: "Vibration",
            data: this.infoData,
          },
        ],
        xaxis: {
          type: "category",
          categories: this.xData,
          labels: {
            show: false,
          },
        },
        yaxis: {
          show: false,
        },
      });
    },
  },
};
</script>
